// sagar changes on 04-10-2023 add ActionsCellIn ActionsCellOut file for check red cross in TimeIn and Time Out fields
import ActionsCellIn from './ActionsCellIn';
import ActionsCellOut from './ActionsCellOut';
export default [
    {
        title: 'Staff Name',
        key: 'NAME',
        minWidth: 100,
    },
    {
        title: 'Shift',
        key: 'shift',
        minWidth: 100,
    },
    {
        title: 'Time(In)',
        sortable: true,
        minWidth: 100,
        render: (h, params) => {
            return h('div', [
                h(ActionsCellIn, {
                    props: {
                        row: params.row
                    }
                }),
            ]);
        }
    },   
    {
        title: 'Time(Out)',
        sortable: true,
        minWidth: 100,
        render: (h, params) => {
            return h('div', [
                h(ActionsCellOut, {
                    props: {
                        row: params.row
                    }
                }),
            ]);
        }
    },
 
    {
        title: 'No .of Hours',
        key: 'hours',
        minWidth: 100,
    },
    {
        title: 'Notes',
        key: 'notes',
        minWidth: 100,
    },
]
